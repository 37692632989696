define([
    'lodash',
    'santa-components',
    'textCommon',
    'componentsCore'
], function (
    _,
    santaComponents,
    textCommon,
    componentsCore
) {
    'use strict';

    function getTextInputFontSize() {
        const fontStyle = this.getFontSize('fnt');
        if (this.props.isMobileView) {
            const size = _.max([13, Number(fontStyle.fontSize.replace('px', ''))]);
            fontStyle.fontSize = `${size}px`;
        }
        return fontStyle;
    }

    const labelUtils = textCommon.labelUtils;

    /**
     * @class components.TextInput
     * @extends {components.BaseTextInput}
     */
    const textInput = {
        displayName: 'TextInput',
        mixins: [textCommon.baseTextInput,
            textCommon.textScaleMixin,
            santaComponents.mixins.inputFocusMixin],
        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode.isRequired
        },
        statics: {
            behaviors: _.assign({}, textCommon.baseTextInput.BASE_TEXT_BEHAVIORS, santaComponents.mixins.inputFocusMixin.INPUT_FOCUS_BEHAVIORS)
        },
        focus() {
            this.refs.input.focus();
        },
        blur() {
            this.refs.input.blur();
        },
        measureComponent(node) {
            const {label} = this.refs;

            const labelHeight = labelUtils.measureComponentWithLabel(this.props.compProp, label, this.props.compData, this.props.isMobileView);
            if (labelHeight) {
                return [{node, type: 'css', changes: {height: labelHeight}}];
            }
        },

        getName() {
            const {compData, compProp} = this.props;
            const formatName = text => text.toLowerCase().replace(' ', '-');

            const {label} = compData;
            if (label) {
                return formatName(label);
            }

            const placeholder = compData.placeholder || compProp.placeholder;
            if (placeholder) {
                return formatName(placeholder);
            }
        },

        getDirection(currDir, hasPhoneFormat) {
            return hasPhoneFormat ? 'ltr' : currDir;
        },

        getSkinProperties() {
            const isEditorMode = this.props.componentViewMode === 'editor';
            const {compProp, isMobileView, isResponsive, compData, compTheme} = this.props;
            const {textAlignment, textPadding, isDisabled} = compProp;
            const showPrefix = !!compData.prefix;
            const hasPhoneFormat = !!compData.phoneFormat;

            const selectedClassSet = {
                'with-validation-indication': this.shouldShowValidityIndication(),
                'show-prefix': showPrefix,
                'required': labelUtils.showRequired(compData, compProp, compTheme, isResponsive),
                'has-phone-format': hasPhoneFormat
            };
            selectedClassSet[`${textAlignment}-direction`] = true;
            const id = {compId: this.props.id, inputId: 'input'};
            const baseTextInputSkinProperties = _.merge(this.getBaseTextInputSkinProperties(), labelUtils.getBaseInputWithLabelSkinProperties({compProp, rawFontSyle: this.getFontSize('fntlbl'), isMobileView, compData, id}));
            baseTextInputSkinProperties[''] = {
                className: this.classSet(selectedClassSet),
                'data-disabled': !!isDisabled,
                'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState()
            };

            const paddingDirection = `padding${_.capitalize(textAlignment)}`;
            baseTextInputSkinProperties.input = _.merge({style: getTextInputFontSize.call(this)}, baseTextInputSkinProperties.input, {
                'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState(),
                className: 'has-custom-focus'
            });
            baseTextInputSkinProperties.prefix = {
                children: compData.prefix
            };
            if (isResponsive) {
                _.assign(baseTextInputSkinProperties.input, {size: '1'});
            }
            const paddingPrefix = !showPrefix || textAlignment !== 'left' ? 0 : 50;
            baseTextInputSkinProperties.input.style[paddingDirection] = `${parseInt(textPadding || 0, 10) + paddingPrefix}px`;
            baseTextInputSkinProperties.input.name = this.getName();

            // Preventing autofill
            const updateReadOnly = inputReadOnly => isEditorMode ? true : inputReadOnly;
            baseTextInputSkinProperties.input.readOnly = updateReadOnly(baseTextInputSkinProperties.input.readOnly);

            return baseTextInputSkinProperties;
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.inputs.TextInput', textInput);

    return textInput;
});
