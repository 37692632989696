define([
    'lodash',
    'componentsCore',
    'santa-components',
    'skins',

    //bootstrapped, but needs registration
    'imageZoom',

    // externalized bootstrapped components
    'components/components/bootstrap/siteButton/siteButton',
    'components/components/bootstrap/dropDownMenu/dropDownMenu',
    'components/components/bootstrap/balata/balata',
    'components/components/bootstrap/infoTip/infoTip',
    'components/components/scriptClass/scriptClass',

    // externalized common modules
    'components/common/mediaCommon/mediaCommon',
    'components/common/translationRequirementsChecker',
    'components/common/wPhotoMaskDataRequirementsChecker',

    // Side effects
    'components/core/svgShapeStylesCollector', //move to svg shape component
    'components/behaviors/compBehaviorsRegistrar', // checked with Nir Natan, should be removed and integrated/inlined into wix code file
    'components/behaviors/scrubBehaviorsRegistrar',
    'components/behaviors/animationBehaviorsRegistrar', //keep in bootstrap / verify against actual usage data

    //bootstrapped
    'components/components/bootstrap/textInput/textInput', //move out and purge usages
    'components/components/bootstrap/sliderGallery/sliderGallery', // move out - verified against site inclusion - 7%
    'components/components/bootstrap/paginatedGridGallery/paginatedGridGallery', // move out - verified against site inclusion - <%3
    'components/components/bootstrap/page/page',
    'components/components/bootstrap/siteBackground/siteBackground',
    'components/components/bootstrap/repeater/repeater', // needed in the fullToDisplayed phase before the comp is downloaded
    'components/components/bootstrap/tpa/tpa',
    'components/components/bootstrap/menuButton/menuButton',
    'components/components/bootstrap/bgImageStrip/bgImageStrip',
    'displayer',
    'backgroundCommon'
], function (
    _,
    componentsCore,
    santaComponents,
    skinsPackage,
    imageZoom,
    siteButton,
    dropDownMenu,
    balata,
    infoTip,
    scriptClass,
    mediaCommon,
    translationRequirementsChecker,
    wPhotoMaskDataRequirementsChecker
) {
    'use strict';

    componentsCore.compRegistrar.register(santaComponents.components.MasterPage.compType, santaComponents.components.MasterPage);
    componentsCore.compRegistrar.register(santaComponents.components.HeaderContainer.compType, santaComponents.components.HeaderContainer);
    componentsCore.compRegistrar.register(santaComponents.components.FooterContainer.compType, santaComponents.components.FooterContainer);
    componentsCore.compRegistrar.register(santaComponents.components.Video.compType, santaComponents.components.Video);
    componentsCore.compRegistrar.register(santaComponents.components.FiveGridLine.compType, santaComponents.components.FiveGridLine);
    componentsCore.compRegistrar.register(santaComponents.components.VerticalLine.compType, santaComponents.components.VerticalLine);
    componentsCore.compRegistrar.register(santaComponents.components.Anchor.compType, santaComponents.components.Anchor);
    componentsCore.compRegistrar.register(santaComponents.components.Image.compType, santaComponents.components.Image);
    componentsCore.compRegistrar.register(santaComponents.components.ZoomedImage.compType, santaComponents.components.ZoomedImage);
    componentsCore.compRegistrar.register(santaComponents.components.ScreenWidthContainer.compType, santaComponents.components.ScreenWidthContainer);
    componentsCore.compRegistrar.register(santaComponents.components.PagesContainer.compType, santaComponents.components.PagesContainer);
    componentsCore.compRegistrar.register(santaComponents.components.PageGroup.compType, santaComponents.components.PageGroup);

    componentsCore.compRegistrar.register('wysiwyg.components.imageZoom', imageZoom.imageZoom);
    componentsCore.compRegistrar.register('wysiwyg.components.ImageZoomDisplayer', imageZoom.imageZoomDisplayer);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.MediaZoom', imageZoom.mediaZoom);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.MobileMediaZoom', imageZoom.mobileMediaZoom);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.TouchMediaZoom', imageZoom.touchMediaZoom);
    componentsCore.compRegistrar.register(santaComponents.components.SiteButton.compType, santaComponents.components.SiteButton);
    componentsCore.compRegistrar.register(santaComponents.components.WRichText.compType, santaComponents.components.WRichText);

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.TouchMediaZoomItem', imageZoom.touchMediaZoomItem);

    componentsCore.compRegistrar.register(santaComponents.components.LegacyContainer.compType, santaComponents.components.LegacyContainer);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.VerticalRepeater', santaComponents.components.LegacyContainer);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.WSiteStructure', santaComponents.components.LegacyContainer);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.ScriptClassForBolt', scriptClass);
    componentsCore.compRegistrar.register(santaComponents.components.Container.compType, santaComponents.components.Container);
    componentsCore.compRegistrar.register(santaComponents.components.ThemeRenderer.compType, santaComponents.components.ThemeRenderer);
    componentsCore.compRegistrar.register(santaComponents.components.AppWidget.compType, santaComponents.components.AppWidget);
    componentsCore.compRegistrar.register(santaComponents.components.FormContainer.compType, santaComponents.components.FormContainer);
    componentsCore.compRegistrar.register(santaComponents.components.GroupContainer.compType, santaComponents.components.GroupContainer);
    componentsCore.compRegistrar.register(santaComponents.components.WPhoto.compType, santaComponents.components.WPhoto);
    componentsCore.compRegistrar.register('wysiwyg.viewer.components.ClipArt', santaComponents.components.ClipArt);

    componentsCore.compRegistrar.register(santaComponents.components.RefComponent.compType, santaComponents.components.RefComponent);
    componentsCore.compRegistrar.register(santaComponents.components.HeaderSection.compType, santaComponents.components.HeaderSection);
    componentsCore.compRegistrar.register(santaComponents.components.FooterSection.compType, santaComponents.components.FooterSection);
    componentsCore.compRegistrar.register(santaComponents.components.MembersAreaSection.compType, santaComponents.components.MembersAreaSection);

    const componentsWithSkins = _.filter(santaComponents.components, comp => !!comp.getComponentSkins);
    _.forEach(componentsWithSkins, comp => {
        skinsPackage.skinsMap.addBatch(comp.getComponentSkins());
    });

    //externalized bootstrapped components
    skinsPackage.skinsMap.addBatch(santaComponents.mixins.scrollMixin.getMixinSkins());

    componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType('wysiwyg.viewer.components.WPhoto', wPhotoMaskDataRequirementsChecker.checkRequirements);

    return {
        siteButton,
        dropDownMenu,
        balata,
        infoTip,
        scriptClass,
        mediaCommon,
        translationRequirementsChecker
    };
});
