/* eslint-disable complexity */
define([
    'santa-components',
    'lodash',
    'prop-types',
    'coreUtils',
    'components/components/bootstrap/balata/balata',
    'santa-animations'
], function (
    santaComponents,
    _,
    PropTypes,
    coreUtils,
    balata,
    santaAnimations
) {
    'use strict';
    const consts = coreUtils.mediaConsts;
    const bgUtils = coreUtils.containerBackgroundUtils;
    const containerUtils = coreUtils.containerBackgroundUtils;
    const {animationProperties = {}} = santaAnimations;

    /**
     * Look for image data in media data
     * @param {object} media
     * @returns {{}}
     */
    function getImageData(media) {
        return media.mediaType === 'WixVideo' ? media.posterImageRef : media;
    }

    /**
     * Look for the background in compDesign and fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getBackgroundData(props) {
        //TODO: here we need to support the new BackgroundX data structure
        return _.get(props.compDesign, ['background'], _.get(props.compData, ['background'])) || {};
    }

    /**
     * Look for media data in compDesign and fallback to compData
     * @param {object} props
     * @returns {{}}
     */
    function getMediaData(props) {
        return getBackgroundData(props).mediaRef || {};
    }

    /**
     * For MediaPlayer we pass video attibutes in the props where for older components they are in the data.
     * Hopefully this will be unified
     * @param {object} data
     * @param {object} properties
     * @returns {{loop: boolean, mute: boolean, autoplay: boolean}}
     */
    function getMediaAttributes(data, properties) {
        const dataAttr = _.pick(data, consts.playback.SUPPORTED_MEDIA_ATTRIBUTES);
        const propertiesAttr = _.pick(properties, consts.playback.SUPPORTED_MEDIA_ATTRIBUTES.concat(consts.supportedParentProps));

        return _.assign(dataAttr, propertiesAttr);
    }

    /**
     * Whether we're in editor viewing env
     * @param {Object} props
     * @return {boolean}
     */
    function isEditorMode(props) {
        return props.componentViewMode === 'editor';
    }

    /**
     * Get the current effect name of a component
     * @param {string|object|undefined} behaviors
     * @param {boolean} isDesktopDevice
     * @param {boolean} isMobileView
     * @returns {string}
     */
    function getBgEffectName({compBehaviors, isDesktopDevice, isMobileView}) {
        const isDesktop = isDesktopDevice();
        // no behaviors, no effect
        if (_.isEmpty(compBehaviors)) {
            return '';
        }
        // ignore effects on non desktop devices and previews
        if (!isDesktop || isMobileView) {
            return '';
        }

        // We historically support one case of multiple behaviors, will be deprecated soon (8/2020).
        const behaviorNames = _(compBehaviors).filter({action: 'bgScrub'}).map('name').sortBy().value();

        //if single behavior, return it
        if (behaviorNames.length === 1) {
            return behaviorNames[0];
        }

        // Fallback to old schema
        // TODO: 8/2020: kill this when we can
        return containerUtils.getBgEffectName(compBehaviors, isDesktop, isMobileView);
    }

    return {
        propTypes: _.defaults({
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
            compStaticBehaviors: santaComponents.santaTypesDefinitions.Component.compStaticBehaviors,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            isInSeo: santaComponents.santaTypesDefinitions.isInSeo,
            bgStyle: PropTypes.object,
            shouldRenderSrc: santaComponents.santaTypesDefinitions.Media.shouldRenderSrc,
            onClick: PropTypes.func,
            // Video props
            mediaAspect: santaComponents.santaTypesDefinitions.SiteAspects.mediaAspect.isRequired,
            renderParts: santaComponents.santaTypesDefinitions.Media.renderParts,
            playbackFormat: santaComponents.santaTypesDefinitions.Media.playbackFormat,
            playbackConfig: santaComponents.santaTypesDefinitions.Media.playbackConfig,
            playbackUrl: santaComponents.santaTypesDefinitions.Media.playbackUrl,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed.isRequired,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode.isRequired,
            enableBackgroundVideo: santaComponents.santaTypesDefinitions.Media.enableBackgroundVideo,
            renderFixedPositionBackgrounds: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionBackgrounds

        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(balata)
        ),

        getInitialState() {
            this.isInViewport = false;
            this.mediaAPI = null;
            this.currentVisibilityState = {hidden: false};
            return {};
        },

        componentDidMount() {
            this.props.mediaAspect.initFeatureDetections();
            this.registerPlayer(this.props);
        },

        componentWillUnmount() {
            this.unregisterPlayer(this.props);
        },

        commponentDidUpdate(prevProps) {
            this.handleVideoDataChange(prevProps, this.props);
        },

        /**
         * automaticly play a video when it loads if needed
         * @param props
         */
        handleAutoplay(props) {
            const isAutoPlay = props.isPlayingAllowed && getMediaData(props).autoplay;
            if (this.isInViewport && isAutoPlay) {
                this._playMedia({persist: true});
            }
        },

        handleVideoDataChange(prevProps, props) {
            const nextMediaData = getMediaData(props);
            const prevMediaData = getMediaData(prevProps);

            // re-register the player in media aspect with the new video id
            if (nextMediaData.videoId && nextMediaData.videoId !== prevMediaData.videoId || // eslint-disable-line no-mixed-operators
                _.includes(props.renderParts.media.video, 'video') && !_.includes(prevProps.renderParts.media.video, 'video')) { // eslint-disable-line no-mixed-operators
                this.unregisterPlayer(prevProps);
                this.registerPlayer(props);
            } else if (nextMediaData.autoplay !== prevMediaData.autoplay && prevProps.isPlayingAllowed) {
                // For hoverbox play/pause video on mode change
                if (nextMediaData.autoplay) {
                    this._playMedia();
                } else {
                    this.pauseMedia();
                }
            }
        },

        registerPlayer(props) {
            // Register video to aspect if there is a video
            const mediaData = getMediaData(props);

            if (_.get(mediaData, 'type') === 'WixVideo' && _.includes(props.renderParts.media.video, 'video')) {
                const registrationOptions = {
                    playerType: 'mediaContainer',
                    mediaData,
                    services: {
                        viewport: {
                            callback: this.onViewportChange,
                            eventTypes: ['in', 'out']
                        },
                        visibility: {
                            callback: this.onVisibilityChange
                        },
                        fileAvailability: {
                            callback: this.onFileAvailability,
                            videoParts: [{
                                name: 'video',
                                quality: mediaData.qualities[0].quality
                            }]
                        }
                    }
                };

                props.mediaAspect.registerPlayer(props.id, registrationOptions);
            }
        },

        unregisterPlayer(props) {
            props.mediaAspect.unregisterPlayer(props.id);
        },

        updatePlayerState(props, state) {
            props.mediaAspect.updatePlayerState(props.id, state);
        },

        updateQualityState(props, videoId, state) {
            props.mediaAspect.updateQualityState(videoId, state);
        },
        /**
         * Updates from media changes
         * @param stateEvent
         */
        onMediaStateChange(stateEvent) {
            const state = this.mediaStateChangeLogic(stateEvent);

            if (!_.isEmpty(state)) {
                this.updatePlayerState(this.props, state);
            }
        },

        /**
         * Handle player event messaging
         * @param {{}} playerStateEvent Event from player
         * @returns {{}}
         */
        mediaStateChangeLogic(playerStateEvent) {
            let state = {};
            switch (playerStateEvent.type) {
                // Initial load
                case consts.eventTypes.MOUNT:
                    state = Object.assign(_.omit(playerStateEvent, 'type'), {volume: 1});
                    break;

                // Video load events - just fill the store with values
                case consts.eventTypes.LOAD:
                    state = _.omit(playerStateEvent, ['type', 'originalEventType']);
                    if (playerStateEvent.playbackState === consts.playbackTypes.READY) {
                        state.mediaReadyState = consts.availabilityReadyStates.IDLE;
                        this.handleAutoplay(this.props);
                    }
                    break;

                // Playback state changes (play, pause, seek etc.)
                case consts.eventTypes.PLAYSTATE:
                    switch (playerStateEvent.playbackState) {
                        case consts.playbackTypes.PLAY_ENDED:
                            state = {
                                playbackState: consts.playbackTypes.PLAY_ENDED,
                                previousPlaybackState: ''
                            };
                            break;
                        // On any other update, if not inside seek state, update playbackState
                        default:
                            state = {
                                playbackState: playerStateEvent.playbackState,
                                mediaReadyState: consts.availabilityReadyStates.IDLE
                            };
                    }
                    break;
                case consts.eventTypes.ERROR:
                    if (playerStateEvent.error === consts.errorTypes.NO_HLS_VIDEO) {
                        this.updateQualityState(this.props, getMediaData(this.props).videoId, {
                            error: '',
                            fallback: true
                        });
                    } else if (playerStateEvent.error === consts.errorTypes.NO_VIDEO_FOUND) {
                        state = {mediaReadyState: consts.availabilityReadyStates.IN_PROCESS};
                    }
                    break;
                case consts.eventTypes.VOLUME:
                    state = {
                        volume: playerStateEvent.volume,
                        muted: playerStateEvent.muted
                    };
                    break;
                // Skip rate
                case consts.eventTypes.RATE:
                    break;
                // Skip time update
                case consts.eventTypes.TIME_UPDATE:
                    break;
            }

            return state;
        },

        videoVisibilityChangeHandler() {
            const params = this.currentVisibilityState;
            const currentState = this.props.mediaAspect.getData(this.props.id);

            if (!params.hidden && params.in) {
                if (currentState.previousPlaybackState === consts.playbackTypes.PLAYING) {
                    this._playMedia();
                } else if (currentState.playbackState === consts.playbackTypes.LOADING ||
                    currentState.playbackState === consts.playbackTypes.READY) {
                    this.handleAutoplay(this.props);
                }
            } else {
                // hidden or out of viewport
                this.pauseMedia();
            }
        },

        /**
         * Updates from viewport service changes
         * @param {{
                in: boolean,
                inPartialAbove: boolean,
                inPartialBelow: boolean,
                inCover: boolean,
                inContained: boolean,
                out: boolean,
                outAbove: boolean,
                outBelow: boolean,
                percentVisible: number 0..1,
                percentOfViewport: number 0..1,
                isFixed: boolean
            }} params
         */
        onViewportChange(params) {
            const shouldUpdateVideoState = !isEditorMode(this.props) && !params.isFixed;
            this.isInViewport = !!params.in;

            Object.assign(this.currentVisibilityState, params);

            if (shouldUpdateVideoState) {
                this.videoVisibilityChangeHandler();
            }
        },

        /**
         * Updates from Page Visibility API service changes
         * @param {{hidden: boolean}} params
         */
        onVisibilityChange(params) {
            const shouldUpdateVideoState = !isEditorMode(this.props);

            Object.assign(this.currentVisibilityState, params);

            if (shouldUpdateVideoState) {
                this.videoVisibilityChangeHandler();
            }
        },

        /**
         * Updates form file availability (quality) service changes
         * @param {{videoId: string, availabilityState: string, type: string, readyQualities: array}} params
         */
        onFileAvailability(params) {
            const {readyQualities} = params;
            if (readyQualities.length) {
                this.updateQualityState(this.props, params.videoId, {readyQualities, error: ''});
            }
            this.updatePlayerState(this.props, {
                mediaReadyState: params.availabilityState
            });
        },

        /**
         *
         * @param mediaAPI
         */
        setMediaAPI(mediaAPI) {
            this.mediaAPI = mediaAPI;
        },

        /**
         * Propagate API calls to media element through components hierarchy
         * @param {string} command
         */
        mediaCommandsLogic(command, params = {}) {
            if (this.mediaAPI) {
                this.mediaAPI(command, params);
            }
        },

        playPreview(callback) {
            this.mediaCommandsLogic('play', {callback});
        },

        playMedia(callback) {
            this._playMedia({persist: true, callback});
        },

        pauseMedia(callback) {
            this.mediaCommandsLogic('pause', {callback});
        },

        stopMedia(callback) {
            this.mediaCommandsLogic('stop', {callback});
        },

        stopPreviewMedia(callback) {
            const reset = true;
            this.mediaCommandsLogic('stop', {reset, callback});
        },

        _playMedia({persist, callback} = {}) {
            if (persist) {
                this.updatePlayerState(
                    this.props,
                    {previousPlaybackState: consts.playbackTypes.PLAYING}
                );
            }
            this.mediaCommandsLogic('play', {callback});
        },

        getIsFullScreenHeight() {
            const bgEffectName = getBgEffectName(this.props);
            const effectProps = animationProperties[bgEffectName];
            if (!bgEffectName || bgEffectName === 'none') {
                return false;
            }

            return effectProps && effectProps.requestFullScreenHeight;
        },

        getIsFullScreenAndFixed() {
            return this.props.renderFixedPositionBackgrounds && this.getIsFullScreenHeight();
        },

        /**
         * Create a balata component
         * @param {{
         *       styleId: string,
         *       id: string,
         *       compData: object,
         *       bgStyle: object,
         *       onClick: function,
         *       compStaticBehaviors: object,
         *       compDesign: object
         *  }} [propsOverrides]
         *
         * @returns {ReactCompositeComponent}
         */
        createFillLayers(propsOverrides) {
            const props = _.assign({}, this.props, propsOverrides);
            const skinData = {
                skin: 'skins.viewer.balata.balataBaseSkin',
                styleId: props.styleId + consts.balataConsts.BALATA
            };
            const bgData = getBackgroundData(props);
            const mediaData = getMediaData(props);
            const imageData = getImageData(mediaData);

            //#SE-21185 : adding different key when switching preview modes.
            //todo: revisit and understand why we lose ref to balata on mobile to desktop editor change (lightbox + hoverbox  scenario)
            const editorView = props.isMobileView ? 'mobile' : 'desktop';
            const bgEffectName = getBgEffectName(this.props);
            const isFullScreenHeight = this.getIsFullScreenHeight();
            const isFullScreenAndFixed = this.getIsFullScreenAndFixed();
            const extraProps = {
                ref: consts.balataConsts.BALATA,
                id: props.id + consts.balataConsts.BALATA,
                key: (isEditorMode(props) ? 'no-playback' : 'playback') + editorView,
                parentId: props.id,
                style: _.defaults(props.bgStyle, consts.defaultMediaStyle, {width: '100%', height: '100%'}),
                compBehaviors: props.compStaticBehaviors,
                compProp: getMediaAttributes(mediaData, this.props.compProp),
                compDesign: props.compDesign, //!! for handleDesignDataBehaviors, relevant for hoverBox in classic only.
                onClick: props.onClick,
                notifyMediaState: this.onMediaStateChange,
                setMediaAPI: this.setMediaAPI,
                isPlayingAllowed: props.isPlayingAllowed,
                isEditorMode: isEditorMode(props),
                shouldRenderSrc: props.shouldRenderSrc,
                // Disable video on mobile for background videos
                enableVideo: this.props.enableBackgroundVideo,
                enableBackgroundPadding: props.isMobileView,
                //layers rendering by existing data
                renderParts: props.renderParts,
                playbackFormat: props.playbackFormat,
                playbackConfig: props.playbackConfig,
                playbackUrl: props.playbackUrl,
                containerStyle: props.style,
                bgData,
                bgEffectName,
                isFullScreenHeight,
                isFullScreenAndFixed,
                label: mediaData.alt
            };
            if (this.props.shouldRenderSrc && !_.isEmpty(imageData)) {
                const mediaDimensions = bgUtils.convertStyleToDimensions(_.defaults(props.mediaDimensions, _.pick(props.style, ['width', 'height'])));
                extraProps.mediaDimensions = mediaDimensions;
                extraProps.imageUrlPreMeasureParams = bgUtils.getImageUrlPreMeasureParams(mediaDimensions, imageData, bgData.fittingType, bgData.alignType, props.isMobileView, props.isInSeo);
            }

            return this.createChildComponent(
                props.compData,
                'wysiwyg.viewer.components.background.Balata',
                skinData,
                extraProps
            );
        }
    };
});
