define([
    'lodash',
    'coreUtils',
    'components/components/bootstrap/balata/balata',
    'components/common/mediaCommon/mediaLogic/fillLogicMixin',
    'components/common/mediaCommon/mediaLogic/mediaPlayerLogicMixin'
], function (_,
             coreUtils,
             balata,
             fillLogicMixin,
             mediaPlayerLogicMixin) {
    'use strict';

    const consts = coreUtils.mediaConsts;

    return {
        balata,
        defaultMediaStyle: consts.defaultMediaStyle,
        popupMediaStyle: consts.popupMediaStyle,
        mediaLogicMixins: {
            fill: fillLogicMixin,
            mediaPlayer: mediaPlayerLogicMixin
        },
        mediaBehaviors: {
            fill: {
                play: {methodName: 'playMedia', params: [{name: 'time', defaultValue: -1}]},
                playPreview: {methodName: 'playPreview'},
                pause: {methodName: 'pauseMedia', params: [{name: 'time', defaultValue: -1}]},
                stop: {methodName: 'stopMedia'},
                stopPreview: {methodName: 'stopPreviewMedia'}
            },
            mediaPlayer: {
                togglePlay: {methodName: 'togglePlayMedia'},
                play: {methodName: 'playMedia', params: [{name: 'time', defaultValue: -1}]},
                playPreview: {methodName: 'previewMedia'},
                pause: {methodName: 'pauseMedia', params: [{name: 'time', defaultValue: -1}]},
                stop: {methodName: 'stopMedia'},
                stopPreview: {methodName: 'stopPreviewMedia'},
                setVolume: {methodName: 'setMediaVolume', params: ['volume']},
                mute: {methodName: 'muteMedia'},
                unMute: {methodName: 'unMuteMedia'},
                seek: {methodName: 'seekMedia', params: ['time']},
                seekStart: {methodName: 'startSeekMedia'},
                seekEnd: {methodName: 'endSeekMedia', params: ['time']},
                enterFullScreen: {methodName: 'enterFullscreen'},
                exitFullScreen: {methodName: 'exitFullscreen'}
            }
        }
    };
});
